<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import {PaginationMixin} from "@/mixins/PaginationMixin";

export default {
  name: "AiLogs",
  mixins : [PaginationMixin],
  components: {AppLoading},
  data() {
    return {
      params : {
        module : 'accident_incidents_module',
        page : 1
      },
      loading : false
    }
  },
  methods : {
    ...mapActions([
      'getAiKidsLogs'
    ]),
    async setLogsTable(page=1) {
      this.loading = true;
      this.params.page = page;
      await this.getAiKidsLogs(this.params);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters([
      'ai_logs',
      'pagination',
    ])
  },

  async created() {
    await this.setLogsTable();
  }


}
</script>

<template>
  <div>
    <div class="row no-gutters mb-2">
      <div class="col-sm-6">
        <h3 class="text-left ml-4">AI KIDS LOGS</h3>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setLogsTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setLogsTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setLogsTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr class="text-center">
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" style="width: 10%" scope="col">USER'S QUESTION</th>
            <th class="align-middle" style="width: 20%" scope="col">AI GENERATED SQL QUERY</th>
            <th class="align-middle" style="width: 50%" scope="col">AI ANSWER</th>
            <th class="align-middle" style="width: 15%" scope="col">USER</th>
            <th class="align-middle" style="width: 15%" scope="col">DURATION</th>
            <th class="align-middle" style="width: 20%" scope="col">DATE TIME</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>

          <template v-else>
            <tr v-for="(log, index) in ai_logs" :key="log.id">
              <td class="align-middle">{{ index + pagination.from }}</td>
              <td class="align-middle">
                <div class="p-3 w-100 text-left">
                  {{ log.question }}
                </div>
              </td>
              <td class="align-middle">
                <div class="p-3 w-100">
                  <pre style="font-size: 11px">{{ log.sql_query }}</pre>
                </div>
              </td>
              <td class="align-middle">
                <div class="p-3 answer w-100">
                  {{ log.answer }}
                </div>
              </td>
              <td class="align-middle">
                <div class="p-3 w-100 text-left">
                  {{ log.user }}
                </div>
              </td>
              <td class="align-middle">
                <div class="p-3 w-100 text-left">
                  {{ Number(log.total_duration / 1000000000).toFixed(2) }} sec/s
                </div>
              </td>
              <td class="align-middle">
                <div class="p-3 w-100 text-left">
                  {{ log.created_at | moment('DD MMMM YYYY hh:mm A') }}
                </div>
              </td>
<!--              <td class="align-middle py-1">-->
<!--                <div class="dropdown dropright" v-if="isElite() || isAllowed('vessels.delete') || isOwnVessel(vessel.id)">-->
<!--                  <button-->
<!--                    id="actionDropdown"-->
<!--                    aria-expanded="false"-->
<!--                    aria-haspopup="true"-->
<!--                    class="mr-2 btn btn-sm btn-secondary force-white-all"-->
<!--                    data-toggle="dropdown"-->
<!--                  >-->
<!--                    <font-awesome-icon icon="cogs"/>-->
<!--                  </button>-->
<!--                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">-->
<!--                    <button class="dropdown-item text-secondary font-weight-bolder"-->
<!--                            @click="vesselOverview(vessel.id)">-->
<!--                      <font-awesome-icon icon="eye"/>-->
<!--                      View-->
<!--                    </button>-->
<!--                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"-->
<!--                            @click="deleteSelected(vessel.id)"-->
<!--                    >-->
<!--                      <font-awesome-icon icon="trash" class="e-text-red"/>-->
<!--                      Delete-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--&lt;!&ndash;                <button class="e-btn e-btn-blue e-btn-sm e-text-white"&ndash;&gt;-->
<!--&lt;!&ndash;                        @click="vesselOverview(vessel.id)" v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                  <font-awesome-icon icon="eye" />&ndash;&gt;-->
<!--&lt;!&ndash;                  View&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--              </td>-->
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.answer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  white-space: pre-wrap;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  font-family: monospace;
}
</style>
